/**
 * 订单相关请求模块
 */
// import qs from 'qs'
import request from '@/utils/request'

export const getOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_payments/setup'
  })
}

export const getOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_payments',
    params
  })
}

export const getOrderExport = (data) => {
  return request({
    method: 'post',
    url: '/cms/shop_payments/export',
    data
    // responseType: 'blob'
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export const getOrderTicketExport = (data) => {
  return request({
    method: 'post',
    url: '/cms/ticketing_payments/export',
    data
    // responseType: 'blob'
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export const getOrderSalesReport = (data) => {
  return request({
    method: 'post',
    url: '/cms/shop_payments/generate_sales_report',
    data
  })
}

export const getOrderFeeSet = (data) => {
  return request({
    method: 'post',
    url: '/cms/shop_payments/generate_delivery_fee_settlement',
    data
  })
}

export const getOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_payments/${id}`
  })
}

export const getDeliveryOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/delivery_orders/setup'
  })
}

export const getDeliveryOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/delivery_orders',
    params
  })
}

export const gettDeliveryOrderExport = (data) => {
  return request({
    method: 'post',
    url: '/cms/delivery_orders/export',
    data
    // responseType: 'blob',
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export const getDeliveryOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/delivery_orders/${id}`
  })
}

export const getAfterSaleOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/after_sales_orders/setup'
  })
}

export const getAfterSaleOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/after_sales_orders',
    params
  })
}

export const getAfterSaleOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/after_sales_orders/${id}`
  })
}

export const getBreakDownOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_payment_items/setup'
  })
}

export const getBreakDownOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_payment_items',
    params
  })
}

export const gettBreakDownOrderExport = (data) => {
  return request({
    method: 'post',
    url: '/cms/shop_payment_items/export',
    data
    // responseType: 'blob',
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export const getBreakDownOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_payment_items/${id}`
  })
}

export const getFileList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/export_jobs',
    params
  })
}

export const getFileSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/export_jobs/setup'
  })
}

export const getDeliverDate = (id) => {
  return request({
    method: 'GET',
    url: `/cms/delivery_orders/${id}/available_delivery_dates`
  })
}

export const getOrderAfterSalesReport = (data) => {
  return request({
    method: 'post',
    url: '/cms/after_sales_orders/export',
    data
  })
}

export const getTicketOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/ticketing_payments/setup'
  })
}

export const getTicketOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/ticketing_payments',
    params
  })
}

export const getTicketingOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/ticketing_payments/${id}`
  })
}

/**
 * @name 酒店订单
 */

export const getHotelOrdersSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/hotel_payments/setup'
  })
}

export const getHotelOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/hotel_payments',
    params
  })
}

export const getDineinOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/dine_in_orders/setup'
  })
}

export const getDineinOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/dine_in_orders',
    params
  })
}

export const getHotelOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotel_payments/${id}`
  })
}

export const getDineinOrderExport = (data) => {
  return request({
    method: 'post',
    url: '/cms/dine_in_orders/export',
    data
    // responseType: 'blob'
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

export const getDineinOrderSalesReport = (data) => {
  return request({
    method: 'post',
    url: '/cms/dine_in_orders/generate_sales_report',
    data
  })
}

export const getDineinOrderFeeSet = (data) => {
  return request({
    method: 'post',
    url: '/cms/dine_in_orders/generate_delivery_fee_settlement',
    data
  })
}

export const getDineinOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/dine_in_orders/${id}`
  })
}

export const getRestOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/rest_payments/setup'
  })
}

export const getRestOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/rest_payments',
    params
  })
}

export const getRestOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/rest_payments/${id}`
  })
}

//优惠券订单
export const getCouponOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/coupon_payments/setup'
  })
}

export const getCounponOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/coupon_payments',
    params
  })
}


export const getCouponOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/coupon_payments/${id}`
  })
}
export const getCouponOrderExport = (data) => {
  return request({
    method: 'post',
    url: '/cms/coupon_payments/export',
    data
    // responseType: 'blob'
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
//优惠券礼包订单
export const getCouponBundleOrderSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/coupon_bundle_payments/setup'
  })
}

export const getCounponBundleOrderQueryList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/coupon_bundle_payments',
    params
  })
}

export const getCouponBundleOrderDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/coupon_bundle_payments/${id}`
  })
}

export const getCouponBundleOrderExport = (data) => {
  return request({
    method: 'post',
    url: '/cms/coupon_bundle_payments/export',
    data
    // responseType: 'blob'
    // headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}
