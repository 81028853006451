<template>
  <div>
    <el-card>
      <div class="viewBtnBox">
        <!-- <el-button type="primary" size="mini" @click="handleClick('view', scope.row)" >{{$t('button.view')}}</el-button> -->
        <!-- <el-button type="primary" size="mini" @click="handleClick('edit')" >{{$t('button.edit')}}</el-button>
        <el-button type="primary" size="mini" @click="deleteClick" >{{$t('button.delete')}}</el-button>
        <el-button type="primary" size="mini" @click="handleClick('copy')" >{{$t('button.copy')}}</el-button> -->
        <!-- <div v-if="modifyData && modifyData.data && modifyData.data.member_actions && modifyData.data.member_actions.custom_actions">
          <el-button v-for="(item, index) in modifyData.data.member_actions.custom_actions" :key="'custom_actions' + index" type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </div> -->
      </div>
    </el-card>
    <showForm :showFormData="showFormData" :data="modifyData.obj"></showForm>
  </div>
</template>

<script>
import { getBreakDownOrderDetails } from '@/services/order'
import showForm from '@/components/showForm'
import jsonData from './jsonData'
import Utils from '@/utils/Utils'
export default {
  components: {
    showForm
  },
  data () {
    return {
      validateVisible: false,
      formData: {},
      modifyData: {},
      showFormData: jsonData.viewDataItem
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.validateVisible = false
    // const dataOperate = JSON.parse(sessionStorage.getItem('dataOperate'))
    // if (to.name === 'managerProductEdit') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'edit', obj: dataOperate.obj }))
    // }
    // if (to.name === 'managerProductCopy') {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: dataOperate.id, type: 'copy', obj: dataOperate.obj }))
    // }
    // if (!(to.name === 'managerProductEdit' || to.name === 'managerProductCopy')) {
    //   sessionStorage.setItem('dataOperate', JSON.stringify({ id: undefined, type: undefined, obj: undefined }))
    // }
    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    init () {
      const dataOperate = JSON.parse(sessionStorage.getItem('dataOperate'))
      this.modifyData = dataOperate
      // this.buttonForm = dataOperate.data
    },
    handleCusClick (data) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      Utils.custOperate(data, this)
      // loading.close()
    },
    async dialogSearch () {
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      const res = await getBreakDownOrderDetails(this.modifyData.id)
      // loading.close()
      if (res.status === 200) {
        this.$nextTick(() => {
        // this.modifyData.obj = res.data.shop_payment
          this.modifyData.obj = res.data.shop_payment
        })
      }
    },
    gobackRouter () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scope>
.viewBtnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
