<template>
  <div class="showForm">
    <div v-for="(item, index) in showFormData" :key="index">
      <el-card v-if="item.type === 'form'">
        <div class="title">{{item.title}}</div>
        <el-form class="form" size="mini" label-width="125px" label-position="left">
          <div v-for="formItem in item.data" :key="formItem.prop">
            <el-form-item :label="formData[item.index][formItem.prop] && formData[item.index][formItem.prop].name">
              <img class="tableImg" :style="{ height: formItem.height}" v-if="formItem.img" :src="formData[item.index][formItem.prop]" alt="">
              <span class="showForImg" v-else-if="formItem.text" v-html="formData[item.index][formItem.prop]">{{ formData[item.index][formItem.prop]}}</span>
              <span class="showForImg" v-else-if="!formItem.object">{{ formData[item.index][formItem.prop] | getNull}}</span>
              <!-- <span v-else v-html="formData[item.index][formItem.prop][formItem.object]">{{ formData[item.index][formItem.prop][formItem.object] }}</span> -->
              <div v-else>
                <span v-if="!formData[item.index][formItem.prop][formItem.object].clickables" v-html="formData[item.index][formItem.prop][formItem.object]"></span>
                <div v-if="formData[item.index][formItem.prop][formItem.object].clickables">
                    <span v-for="(itemhref, indexhref) in formData[item.index][formItem.prop][formItem.object].clickables" :key="indexhref"><span v-html="itemhref.name"></span></span>
                </div>
              </div>
            </el-form-item>
            <el-divider></el-divider>
          </div>
        </el-form>
      </el-card>
      <el-card v-if="item.type === 'table'" class="margintop20">
        <div class="title">{{item.title}}</div>
        <createTable :tableDataItem="item.data" :operateVisible="false" :tableData="data[item.prop]"></createTable>
      </el-card>
    </div>
  </div>
</template>

<script>
import createTable from './createTable'
export default {
  components: {
    createTable
  },
  props: {
    showFormData: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      tableData: [],
      formData: [],
      changeData: {}
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.init()
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.formData = []
      console.log(this.data)
      if (this.showFormData.length > 0) {
        this.showFormData.forEach(res => {
          this.changeData = {}
          if (res.type === 'form') {
            res.data.forEach(req => {
              // 判断是否为数组
              if (this.data[req.prop] instanceof Array) {
                // 判断数组长度
                if (this.data[req.prop].length < 1) {
                  this.$set(this.changeData, req.prop, '')
                } else {
                  this.data[req.prop].forEach(dataitem => {
                    if (Object.keys(dataitem).length > 0) {
                      let str = ''
                      Object.keys(dataitem).forEach(objres => {
                        if (dataitem[objres] instanceof Array) {
                          if (dataitem[objres].length < 1) {
                            str += '' + '\n'
                          } else {
                            dataitem[objres].forEach(objresson => {
                              if (Object.keys(objresson).length > 0) {
                                Object.keys(objresson).forEach(objressonres => {
                                  str += (objressonres === 'id') ? '' : (objresson[objressonres] + '\n')
                                })
                              } else {
                                str += objresson.join(',') + '\n'
                              }
                            })
                          }
                        } else {
                          str += (objres === 'id') ? '' : (dataitem[objres] + '\n')
                        }
                      })
                      this.$set(this.changeData, req.prop, str)
                    } else {
                      this.$set(this.changeData, req.prop, this.data[req.prop].join(','))
                    }
                  })
                }
              } else {
                this.$set(this.changeData, req.prop, this.data[req.prop])
              }
            })
          }
          this.formData.push(this.changeData)
        })
      }
      console.log('changeData', this.formData)
    }
  }
}
</script>

<style lang="scss" scope>
.showForImg {
  img {
    height: 100px;
  }
}
.el-divider--horizontal {
  margin: 0
}
.showForm {
  .title {
    height: 40px;
    color: #fff;
    background: $primary-color;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  .el-card__body {
    padding: 0 0 30px 0;
  }
  .el-form-item--mini.el-form-item {
    margin-bottom: 0;
    min-height: 40px;
    display: flex;
    padding: 0 20px;
    align-items: center;
  }
}
</style>
