import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'user_order',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'shop',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'supplier',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'shop_item',
    label: '',
    Object: 'value',
    width: '300'
  }, {
    prop: 'shop_item_stock',
    label: '',
    Object: 'value',
    width: '300'
  }, {
    prop: 'preferences',
    label: '',
    Object: 'value',
    width: '300'
  }, {
    prop: 'price',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'count',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'delivery',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'status',
    back: true,
    label: '',
    Object: 'value',
    width: '180'
  }],
  dialogBasicData: [{
    collname: '基本信息',
    alterFormData: [{
      span: 4,
      label: '',
      name: '上架',
      prop: 'online',
      type: 'position',
      value: 1
    }, {
      span: 4,
      label: '',
      name: '推荐',
      prop: 'recommend',
      type: 'position',
      value: 0
    }, {
      span: 4,
      label: '',
      name: '可搜索',
      prop: 'searchable',
      type: 'position',
      value: 0
    }, {
      span: 4,
      label: '',
      name: '订单免邮',
      prop: 'free_delivery',
      type: 'position',
      value: 0
    }, {
      span: 24,
      label: '上架时间',
      datetime: true,
      type: 'date',
      prop: 'online_time'
    }, {
      span: 8,
      label: '店铺',
      type: 'select',
      prop: 'shop',
      Object: 'id',
      options: [],
      rule: [
        { required: true, message: '请选择店铺', trigger: ['blur', 'change'] }
      ],
      change: true
    }, {
      span: 8,
      label: '供应商',
      type: 'select',
      prop: 'supplier',
      Object: 'id',
      options: []
    }, {
      span: 8,
      label: '商品类型',
      type: 'select',
      prop: 'item_type',
      options: [],
      rule: [
        { required: true, message: '请选择商品类型', trigger: ['blur', 'change'] }
      ]
    }, {
      span: 24,
      label: '商品类别',
      group: [],
      marginVisible: false,
      name: 'category_ids',
      Object: 'id',
      prop: 'shop_categories',
      string: true,
      type: 'checkGroup'
    }, {
      span: 24,
      label: '商品子类别',
      prop: 'shop_sub_categories',
      name: 'shop_sub_category_ids',
      Object: 'id',
      string: true,
      marginVisible: false,
      type: 'checkGroup',
      group: []
    }, {
      span: 12,
      label: '中文名称',
      type: 'input',
      prop: 'name',
      rule: [
        { required: true, message: '请输入中文名称', trigger: 'blur' }
      ]
    }, {
      span: 12,
      label: '英文名称',
      type: 'input',
      prop: 'name_en'
    }, {
      span: 24,
      label: '同一用户购买上限',
      type: 'number',
      prop: 'maximum_limit_per_user'
    }, {
      span: 24,
      label: '初始销售',
      type: 'number',
      prop: 'init_sale',
      rule: [
        { required: true, message: '初始销售不能为空' },
        { type: 'number', message: '初始销售为数字值' }
      ]
    }, {
      span: 12,
      label: '简要描述(中文)',
      type: 'input',
      prop: 'short_description'
    }, {
      span: 12,
      label: '简要描述(英文)',
      type: 'input',
      prop: 'short_description_en'
    }, {
      span: 24,
      label: '描述(中文)',
      type: 'editor',
      prop: 'description'
    }, {
      span: 24,
      label: '描述(英文)',
      type: 'editor',
      prop: 'description_en'
    }, {
      span: 24,
      label: '预设标签',
      group: [{
        name: '春节特惠(CNY Special)',
        prop: '春节特惠'
      }, {
        name: '热销榜单(Best Sales)',
        prop: '热销榜单'
      }, {
        name: '冷冻冷藏(Frozen Food)',
        prop: '冷冻冷藏'
      }],
      marginVisible: false,
      prop: 'global_labels',
      string: true,
      type: 'checkGroup'
    }]
  }, {
    collname: '特色标签',
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: '新建一个特色标签',
      prop: 'labels',
      operate: 'true',
      array: [{
        span: 12,
        label: '中文名',
        type: 'input',
        prop: 'name'
      }, {
        span: 12,
        label: '英文名',
        type: 'input',
        prop: 'name_en'
      }]
    }]
  }, {
    collname: '图片',
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: '新建一个图片',
      prop: 'images',
      operate: 'true',
      limit: true,
      array: [{
        span: 24,
        label: '图片文件',
        type: 'avatar',
        prop: 'image_url',
        file: 'img',
        limit: 10
      }]
    }]
  }, {
    collname: '购买须知',
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: '添加须知',
      prop: 'notices',
      operate: 'true',
      array: [{
        span: 24,
        label: 'Display order',
        type: 'number',
        prop: 'display_order',
        value: 10000
      }, {
        span: 12,
        label: '标题(中文)',
        type: 'input',
        prop: 'title'
      }, {
        span: 12,
        label: '标题(英文)',
        type: 'input',
        prop: 'title_en'
      }, {
        span: 12,
        label: '内容(中文)',
        type: 'input',
        prop: 'detail'
      }, {
        span: 12,
        label: '内容(英文)',
        type: 'input',
        prop: 'detail_en'
      }]
    }]
  }, {
    collname: '商品型号',
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: '新建一个商品型号',
      prop: 'shop_item_stocks',
      operate: 'true',
      other: true,
      array: [{
        span: 24,
        label: '',
        name: '上架',
        prop: 'online',
        type: 'position',
        value: 1
      }, {
        span: 12,
        label: '中文名称',
        type: 'input',
        prop: 'name'
      }, {
        span: 12,
        label: '英文名称',
        type: 'input',
        prop: 'name_en'
      }, {
        span: 6,
        label: 'SKU ID',
        type: 'input',
        prop: 'sku_id'
      }, {
        span: 6,
        label: '价格（元）',
        type: 'input',
        prop: 'price',
        value: '0.0'
      }, {
        span: 6,
        label: '成本价（元）',
        type: 'input',
        prop: 'cost_price',
        value: '0.0'
      }, {
        span: 6,
        label: '划线价（元）',
        type: 'input',
        prop: 'line_price',
        value: '0.0'
      }, {
        span: 24,
        label: '图片',
        type: 'avatar',
        prop: 'image_url',
        file: 'img',
        limit: 1
      }, {
        span: 6,
        label: '可销售库存',
        type: 'number',
        prop: 'saling_stock',
        rule: [
          { required: true, message: '初始销售不能为空' },
          { type: 'number', message: '初始销售为数字值' }
        ]
      }, {
        span: 6,
        label: '预警库存',
        type: 'number',
        prop: 'warning_stock',
        rule: [
          { required: true, message: '初始销售不能为空' },
          { type: 'number', message: '初始销售为数字值' }
        ]
      }, {
        span: 6,
        label: '锁定库存',
        type: 'number',
        prop: 'locking_stock',
        rule: [
          { required: true, message: '初始销售不能为空' },
          { type: 'number', message: '初始销售为数字值' }
        ]
      }]
    }]
  }, {
    collname: '喜好',
    alterFormData: [{
      span: 24,
      label: '',
      type: 'array',
      btnName: '新建一个喜好',
      prop: 'shop_item_preferences',
      operate: 'true',
      array: [{
        span: 12,
        label: '标题',
        type: 'input',
        prop: 'name'
      }, {
        span: 12,
        label: '显示顺序',
        type: 'number',
        prop: 'display_order',
        value: 100000
      }, {
        span: 12,
        label: '最小选择数量',
        type: 'number',
        prop: 'min_count'
      }, {
        span: 12,
        label: '最大选择数量',
        type: 'number',
        prop: 'max_count'
      }, {
        span: 24,
        label: '喜好选择',
        type: 'array',
        prop: 'preference_options',
        btnName: '新建一个喜好选项',
        operate: 'true',
        array: [{
          span: 12,
          label: '名称',
          type: 'input',
          prop: 'name'
        }, {
          span: 12,
          label: '价格',
          type: 'input',
          prop: 'price'
        }]
      }]
    }]
  }],
  copyDataItem: [{
    title: '基本信息',
    type: 'form',
    index: 0,
    data: [{
      label: '中文名称',
      prop: 'name'
    }, {
      label: '店铺',
      prop: 'shop',
      object: 'name'
    }, {
      label: '商品类型',
      prop: 'item_type'
    }, {
      label: '排序优先级',
      prop: 'item_type1'
    }, {
      label: '单位',
      prop: 'item_type2'
    }, {
      label: '上架',
      prop: 'online'
    }, {
      label: '描述(中文)',
      prop: 'description',
      text: true
    }, {
      label: '价格',
      prop: 'item_type3'
    }, {
      label: '最低价',
      prop: 'item_type4'
    }, {
      label: '取货方式',
      prop: 'item_type5'
    }, {
      label: '送货上门酒店',
      prop: 'item_type6'
    }, {
      label: '特色标签',
      prop: 'labels'
    }, {
      label: '须知添加',
      prop: 'item_type7'
    }, {
      label: '购买须知',
      prop: 'notices'
    }]
  }, {
    title: '商品型号',
    type: 'table',
    prop: 'shop_item_stocks',
    data: [{
      prop: 'name',
      label: '中文名称',
      width: '70',
      span: true
    }, {
      prop: 'image_url',
      label: '图片',
      width: '60',
      height: '40px',
      img: true
    }, {
      prop: 'price',
      label: '价格',
      width: '40',
      span: true
    }, {
      prop: 'cost_price',
      label: '成本价',
      span: true,
      width: '40'
    }, {
      prop: 'basic_information',
      label: '优惠价',
      span: true,
      width: '40'
    }, {
      prop: 'shop_item_stocks_information',
      label: '已售',
      span: true,
      width: '40'
    }, {
      prop: 'saling_stock',
      label: '可销售库存',
      span: true,
      width: '40'
    }, {
      prop: 'warning_stock',
      label: '预警库存',
      span: true,
      width: '40'
    }, {
      prop: 'locking_stock',
      label: '锁定库存',
      span: true,
      width: '40'
    }, {
      prop: 'online',
      label: '上架',
      span: true,
      width: '40'
    }]
  }, {
    title: '喜好',
    type: 'table',
    prop: 'shop_item_preferences',
    data: [{
      prop: 'name',
      label: '标题',
      width: '70',
      span: true
    }, {
      prop: 'display_order',
      label: '显示顺序',
      width: '60',
      span: true
    }, {
      prop: 'min_count',
      label: '最小选择数量',
      width: '40',
      span: true
    }, {
      prop: 'max_count',
      label: '最大选择数量',
      span: true,
      width: '40'
    }, {
      prop: 'preference_options',
      label: '选项',
      array: true,
      object: 'name',
      width: '40'
    }]
  }],
  viewDataItem: [{
    title: i18n.t('form.order.userDeliverDetails'),
    type: 'form',
    index: 0,
    data: [{
      label: i18n.t('form.common.shop'),
      prop: 'shop',
      object: 'value'
    }, {
      label: i18n.t('form.order.orderNumber'),
      prop: 'id',
      object: 'value'
    }, {
      label: i18n.t('form.common.status'),
      prop: 'status',
      object: 'value'
    }, {
      label: i18n.t('table.order.ordersDetail'),
      prop: 'orders',
      object: 'value'
    }, {
      label: i18n.t('table.order.ordersDetail'),
      prop: 'product_detail',
      object: 'value'
    }, {
      label: i18n.t('table.order.ordersDetail'),
      prop: 'shipping_detail',
      object: 'value'
    }, {
      label: i18n.t('table.order.ordersDetail'),
      prop: 'meet_moq',
      object: 'value'
    }, {
      label: i18n.t('table.order.ordersDetail'),
      prop: 'host',
      object: 'value'
    }]
  }, {
    title: i18n.t('form.common.changeHistory'),
    type: 'table',
    prop: 'versions',
    data: [{
      prop: 'id',
      label: 'id',
      width: '20',
      span: true
    }, {
      prop: 'operator',
      label: i18n.t('form.common.operator'),
      width: '50',
      span: true
    }, {
      prop: 'changeset',
      label: i18n.t('form.common.changeTime'),
      width: '100',
      span: true
    }, {
      prop: 'created_at',
      label: i18n.t('form.common.creationTime'),
      date: 'YYYY年mm月dd日 HH:MM',
      width: '50'
    }]
  }]
}
